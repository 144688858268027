/* eslint-disable */
import { Store } from "pullstate";

export const defaultConfig = {
  theme: {
    main: '#bababa',
    mainShade: '#bababa',
    text: '#000000',
    button: '#bababa',
  },
  logo: {
    coloredImage: 'https://storage.googleapis.com/indisi-images-dev/81a06454027ed791e470b4fbf1f41adb44d903391a22fa534921a72a73d83961',

  },
  loadingLogoTheme: {
    background: '#ffffff',
    spinner: ['#037FFF', '#037FFF'],
  },
}

const initialState = {
  connectionInvitation: "",
  uid: null,
  eventId: null,
  degreeData: null,
  progressBar: false,
  otpSentAt: null,
  transactionId: null,
  referrerApp: false,
  issuerData: null,
  isScrollbarVisible: false,
  majorComponentsHeights: {
    header: null,
    footer: null,
    mainHeight: null
  }
};

const UiStore = new Store({
  isAuthenticated: false,
  sessionToken: "",
  alreadyConnected: false,
  configs: defaultConfig,
  ...initialState,
});

export default UiStore;

UiStore.subscribe(
  (s) => s,
  (userStore) => {
    localStorage.setItem("store", JSON.stringify(userStore));
  }
);

export const rehydrateStore = async () => {
  try {
    const user = localStorage.getItem("store");
    if (user !== null) {
      const data = JSON.parse(user);
      UiStore.replace({ ...data, ...initialState });
    }
  } catch (e) {
    console.log("Error occured");
  }
};

export const updateStore = (key, value) => {
  UiStore.update((s) => {
    s[key] = value;
  });
};

export const fetchFromStore = (key) => {
  const mainStore = UiStore.getRawState();
  return mainStore[key];
};

export const setEventId = (v) => updateStore("eventId", v);
export const setIsAuthenticated = (v) => updateStore("isAuthenticated", v);
export const setSessionToken = (v) => updateStore("sessionToken", v);
export const setConnectionInvitation = (v) =>
  updateStore("connectionInvitation", v);
export const setUid = (v) => updateStore("uid", v);
export const setDegreeData = (v) => updateStore("degreeData", v);
export const setAlreadyConnected = (v) => updateStore("alreadyConnected", v);
