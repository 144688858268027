import React, { useState } from 'react';
import { getHost } from '../../utils/helpers';
import './styles.css';

const fn = () => {
  const issuer = getHost(window.location.href);
  let orgTemp = localStorage.getItem('org-temp');
  if (orgTemp) {
    orgTemp = JSON.parse(orgTemp);
  }
  const configs = {
    theme: {
      main: '#bababa',
      mainShade: '#bababa',
      text: '#000000',
      button: '#bababa',
    },
    logo: {
      coloredImage: (orgTemp?.logo && orgTemp?.issuerKey === issuer) ? orgTemp?.logo : 'https://storage.googleapis.com/indisi-images-dev/81a06454027ed791e470b4fbf1f41adb44d903391a22fa534921a72a73d83961',

    },
    loadingLogoTheme: {
      background: '#ffffff',
      spinner: [orgTemp?.mainColor ?? '#037FFF', orgTemp?.mainColor ?? '#037FFF'],
    },
  };
  return configs;
};
export default function DefaultSpinner() {
  console.log('spinnerLogo');
  const [cnfg, setCnfg] = useState(fn());

  return (
    <div
      className="loader-wrapper"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        backgroundColor: '#ffffff',
        position: 'absolute',
        top: 0,
        left: 0,
      }}>

      <span
        id="loader"
        style={{
          // backgroundColor: '#ffffff',
          borderBottom: `2px solid ${cnfg?.loadingLogoTheme?.spinner[0] ?? '#ffffff'}`,
          borderTop: `2px solid ${cnfg?.loadingLogoTheme?.spinner[1] ?? '#ffffff'}`,
        }}>
        <span
          id=""
          style={{
            backgroundColor: '#ffffff',
          }} />

      </span>
      <img
        className="loading-img"
        style={cnfg?.loadingLogoTheme?.style ?? {}}
        src={cnfg?.logo?.coloredImage ?? ''}
        alt="spinner" />
    </div>
  );
}
