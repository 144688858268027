import { AppBar, Backdrop, Card, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { registerInDevtools } from 'pullstate';
import { useEffect } from 'react';
import Router from './Router';
import accountUnderMaintenance from './assets/images/accountUnderMaintenance.svg';
import DefaultSpinner from './components/spinner/default';
import { addFont, getHost, transformConfigToFrontend } from './utils/helpers';
import UiStore, { defaultConfig, fetchFromStore, rehydrateStore, updateStore } from './utils/store';

function App() {
  const configs = UiStore.useState((s) => s.configs);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  window.snackbar = (msg, variant) => {
    console.log('msg', msg);
    if (msg) {
      return enqueueSnackbar(
        msg?.slice(0, 1).toUpperCase().concat(msg?.slice(1)),
        {
          variant,
          style: { overflowWrap: 'anywhere' },
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          // action: (key) => (
          //   <IconButton
          //     size="small"
          //     aria-label="close"
          //     color="inherit"
          //     onClick={() => closeSnackbar(key)}
          //   >
          //     <Close fontSize="small" />
          //   </IconButton>
          // )
        }
      );
    }
  };
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1340,
        xl: 1536,
      },
    },
    palette: {
      primary: { main: configs?.theme?.main ?? '#0170E0', contrastText: '#fff' },
      secondary: { main: configs?.theme?.text ?? '#00af52', contrastText: '#fff' },
      black: { main: '#000000', contrastText: '#fff' },
      error: {
        main: '#FF0000',
      }
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '12px',
        },
      },
    },
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: ({ ownerState }) => ({
            textTransform: 'initial',
            borderRadius: '5px',
            ...(ownerState.variant === 'contained' && {

              // Some CSS
              borderRadius: '5px',
              background: `${configs.theme.button}`,
              color: '#ffffff',
              padding: '10px 48px',
              '&:hover': {
                background: `${configs.theme.button}`,
                opacity: 0.7,
              }
            }),
            ...(ownerState.variant === 'outlined' && {

              // Some CSS
              borderRadius: '5px',

            }),
          }),
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: '1rem'
          },
        },
      },
      // MuiTypography: {
      //   styleOverrides: {
      //     // Name of the slot
      //     root: {
      //       color: env.theme.text,
      //     },
      //   },
      // },
    },
  });
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      // disable console in prod
      window.console.log = () => { };
    }
  }, []);

  useEffect(() => {
    rehydrateStore();
    registerInDevtools({
      UiStore,
    });
  }, []);

  useEffect(() => {
    let interval = null;
    try {
      const issuerStored = fetchFromStore('configs');
      const issuer = getHost(window.location.href);
      if (issuer !== issuerStored.issuerKey) {
        updateStore('configs', defaultConfig);
      }

      interval = setInterval(() => {
        console.log('checking...');
        if (window?._env_?.ISSUER?.theme) {
          console.log('inside setInterval==>>', window?._env_?.ISSUER);
          window._env_.ISSUER = transformConfigToFrontend(window?._env_?.ISSUER);
          console.log('sssss', window._env_.ISSUER);

          updateStore('configs', {

            ...window?._env_?.ISSUER
          });
          // so that var(--main-color) can be used in .css files
          document.documentElement.style
            .setProperty('--main-color', window?._env_?.ISSUER?.theme.main);
          document.documentElement.style
            .setProperty('--button-color', window?._env_?.ISSUER?.theme.button);
          document.documentElement.style
            .setProperty('--main-shade-color', window?._env_?.ISSUER?.theme.mainShade);
          document.querySelector('#meta-title').content = window?._env_?.ISSUER?.metadata.title;
          if (document.querySelector('head > title')) {
            document.querySelector('head > title').innerHTML = window?._env_?.ISSUER?.metadata.title;
          }
          document.querySelector('#meta-description').content = window?._env_?.ISSUER?.metadata.description;
          document.querySelector('#favIcon').href = window?._env_?.ISSUER?.metadata.favIcon;

          if (window?._env_?.ISSUER?.theme.font) {
            document.documentElement.style
              .setProperty('--global-font-family', `${window?._env_?.ISSUER?.theme.font}`);
          }
          if (window?._env_?.ISSUER?.theme.fontUrl) {
            addFont(window._env_.ISSUER.theme.font, window._env_.ISSUER.theme.fontUrl);
          }

          clearInterval(interval);
        }
      }, 1000);
    } catch (e) {
      updateStore('configs', defaultConfig);

      clearInterval(interval);
      console.log('error in query params:', e);
    }
    setTimeout(() => {
      clearInterval(interval);
      // window.location.href = 'https://trential.com';
    }, 6000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  console.log('app.js: configs', configs);
  return configs?.configId ? (

    <ThemeProvider theme={theme}>

      {configs.isDemo ? (
        <AppBar>
          <p style={{ fontSize: '1rem', textAlign: 'center' }}>This is for demo purposes only</p>
        </AppBar>
      ) : null}
      <Router
      />
      {!configs.active ? (
        <Backdrop
          open={!configs.active}
          sx={{
            zIndex: 9999 }}>
          <Card
            raised
            sx={{
              position: 'relative',
              // left: '-50%',
              // top: '-60%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '30vw',
              minWidth: '350px',
              padding: '0 1em 4em',
              borderRadius: '8px'
            }}>

            <img src={accountUnderMaintenance} style={{ width: '50%' }} alt="account-under-maintainence" />
            <Typography variant="h6">We are under maintenance</Typography>
            <Typography variant="body2" sx={{ color: '#A0A0A0' }}>
              We apologise for any inconvenience caused.

            </Typography>
            <Typography variant="body2" sx={{ color: '#A0A0A0' }}>
              We will be back shortly.

            </Typography>

          </Card>

        </Backdrop>

      ) : null}
    </ThemeProvider>
  )
    : <DefaultSpinner />;
}

export default App;
