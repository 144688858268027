import Grid from '@mui/material/Grid';
import { lazy, useRef } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import UiStore, { setAlreadyConnected, setIsAuthenticated, updateStore } from './utils/store';

const Base = lazy(() => import('./containers/base'));
const GeneralDetails = lazy(() => import('./containers/home'));
const Auth = lazy(() => import('./containers/auth'));
const InstructionsAndFaq = lazy(() => import('./containers/information'));

function Router(props) {
  const containerInMainRef = useRef(null);
  const isAuthenticated = UiStore.useState((s) => s.isAuthenticated);
  const referrerApp = UiStore.useState((s) => s.referrerApp);

  console.log('referrerApp', referrerApp);
  console.log('cop');

  if (referrerApp || document.URL.includes('referrer=holder-app')) {
    const parser = new URL(window.location);
    parser.searchParams.set('referrer', 'holder-app');
    updateStore('referrerApp', true);
    console.log(parser);
  }

  const logOut = () => {
    setAlreadyConnected(false);
    setIsAuthenticated(false);
    window.location.href = '/';
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    // sx={{ width: '100vw', height: '100vh', padding: '0', overflowY: 'hidden', margin: 0, backgroundColor: '#E5E5E5' }}
    >

      <BrowserRouter>
        <Routes>
          <Route
            path="/faq"
            element={(
              <Base containerInMainRef={containerInMainRef}>
                <InstructionsAndFaq />
              </Base>
            )}
          />
          <Route
            path="/instructions"
            element={(
              <Base containerInMainRef={containerInMainRef}>
                <InstructionsAndFaq />
              </Base>
            )}
          />
          {isAuthenticated === true && (
            <Route
              path="/app.html"
              element={(
                <Base containerInMainRef={containerInMainRef}>
                  <GeneralDetails containerInMainRef={containerInMainRef} />
                </Base>
              )}
            />
          )}

          {isAuthenticated === true ? (
            <Route
              path="*"
              element={(
                <Base containerInMainRef={containerInMainRef}>
                  <GeneralDetails containerInMainRef={containerInMainRef} />
                </Base>
              )}
            />
          ) : (
            <Route
              path="*"
              element={(
                <Auth containerInMainRef={containerInMainRef} />

              )}
            />
          )}
        </Routes>
      </BrowserRouter>
    </Grid>
  );
}

export default Router;
