/* eslint-disable no-undef */
/* eslint-disable prefer-rest-params */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-indent */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import CustomSnackbar from './components/custom/snackbar';
import ErrorFallback from './components/fallback/errorHandlers/page500';
import Spinner from './components/spinner';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Create a root.
const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.REACT_APP_ENV == 'production') {
  const googleAnalytics = document.createElement('script');

  googleAnalytics.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS}`);

  googleAnalytics.onload = function () {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS);
  };

  const head = document?.getElementsByTagName('head')[0];
  head?.insertBefore(googleAnalytics, head?.children[1]);
}
// Initial render
root.render(<ErrorBoundary
  FallbackComponent={ErrorFallback}
>
  <Suspense fallback={<Spinner />}>
    <CustomSnackbar>
      <App />
    </CustomSnackbar>
  </Suspense>
            </ErrorBoundary>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
