/* eslint-disable react/button-has-type */
import React from 'react';
import Page500Background from '../../../../assets/images/error-background.jpg';
import './styles.css';

export default function Page500({ error, resetErrorBoundary, reason = '' }) {
  console.log('error', error);
  return (
    <div className="container-500" style={{ backgroundImage: `url(${Page500Background})` }}>
      <h1 className="h1--500">5 0 0</h1>
      <p className="p-500">Oops! Something is wrong.</p>
      {reason === '' ? (
        <>
          <code className="code-500">
            Error:
            {' '}
            {error.message}
            <br />
            For details, look in the console
          </code>
          <p className="p-500">Try again after clearing browser data, cookies and localstorage.</p>
          <button className="button-500" onClick={resetErrorBoundary}>
            Click Here to
            {' '}
            <i className="fas fa-recycle" />
            {' '}
            refresh and
            {' '}
            <i className="fas fa-trash-alt" />
            {' '}
            clear localstorage
          </button>
          <p style={{ padding: '1em' }}>The above button may not work sometimes. Refresh or clear cookies manually.</p>
        </>

      ) : (
        <code className="code-500">
          Error:
          {' '}
          {reason}

        </code>
      )}

    </div>
  );
}
