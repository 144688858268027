import React from 'react';
import UiStore from '../../utils/store';
import './styles.css';

export default function Spinner() {
  console.log('spinnerLogo');
  const configs = UiStore.useState((s) => s.configs);
  return (
    <div
      className="loader-wrapper"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        backgroundColor: '#ffffff',
        position: 'absolute',
        top: 0,
        left: 0,
      }}>

      <span
        id="loader"
        style={{
          // backgroundColor: '#ffffff',
          borderBottom: `2px solid ${configs?.loadingLogoTheme?.spinner[0] ?? '#ffffff'}`,
          borderTop: `2px solid ${configs?.loadingLogoTheme?.spinner[1] ?? '#ffffff'}`,
        }}>
        <span
          id=""
          style={{
            backgroundColor: '#ffffff',
          }} />

      </span>
      <img
        className="loading-img"
        style={configs?.loadingLogoTheme?.style ?? {}}
        src={configs?.logo?.coloredImage ?? ''}
        alt="spinner" />
    </div>
  );
}
